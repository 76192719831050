.titleSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* padding-block: 3rem; */
}
.imgSection {
  padding-left: 5rem;
  padding-block: 3rem;
  text-align: left;
}
.titleForVideo {
  font-family: "Outfit", sans-serif !important;
  font-weight: 700 !important;
  font-size: 54px !important;
  text-transform: uppercase !important;
  line-height: 68.04px !important;
  max-width: 80%;
}

.mobileImg {
  width: 320px;
  height: 673px;
}
.video {
  /* width: 90%;
  height: 426px; */
  width: 100%;
  height: 100%;
}
@media (max-width: 1440px) {
  .mobileImg {
    width: 240px;
    height: 505px;
  }
  .titleForVideo {
    font-size: 40px !important;
    line-height: 51px !important;
  }
  .imgSection {
    padding-left: 2rem;
  }
}

@media (max-width: 767px) {
  .titleSection {
    padding-left: 0rem;
  }
  .mobileImg {
    width: 190px;
    height: 350px;
  }
  .imgSection {
    display: none;
  }
}
