.innerTicket {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  background-color: #0bec81;
  padding: 1.5rem 1rem;
}

.titleRight {
  font-family: "Outfit", sans-serif !important;
  font-weight: 600 !important;
  line-height: 20px;
  font-size: 14px;
  color: #000000;
}
.subTitleRight {
  font-family: "Outfit", sans-serif !important;
  font-weight: 400 !important;
  line-height: 20px;
  font-size: 14px;
  color: #000000;
}
