.innerBoxSeller {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  gap: 1rem;
  background-color: #0bec81;
  padding-block: 2rem;
  padding-right: 2rem;
}
.titleSeller {
  font-family: "Outfit", sans-serif !important;
  font-weight: 600;
  text-align: left;
  font-size: 20px !important;
  line-height: 40px;
  /* margin-block: 20px !important; */
  color: #000;
}
.descriptionSeller {
  font-family: "Outfit", sans-serif !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  font-size: 12px !important;
  color: #000;
}
.sellerImg {
  width: 64px;
  height: 61px;
}
