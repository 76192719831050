.main-footer-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-section {
  max-width: 80%;
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  padding-block: 4rem;
  position: relative;
}

.footer-left-section {
  grid: 2rem;
}

.app-logo {
  width: 40%;
  height: auto;
  padding-block-end: 2rem;
}

.footer-para {
  color: #8e8e8e;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;
  padding-inline-end: 4rem;
  width: 70%;
}

.footer-right-section {
  gap: 2rem;
}

.text {
  color: #adadad;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  padding-right: 6rem;
  padding-block-end: 1rem;
}

.item-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-block-end: 0.6rem;
  color: #3e3e3e;
  font-size: 1.2rem;
  font-weight: 400;
}

.footer-para2 {
  color: #adadad;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;
}

.info {
  color: #adadad;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;
  text-align: end;
  padding-right: 3rem;
}

strong {
  color: #000;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;
}

.footer-link {
  color: #11c4a3;
  cursor: pointer;
  text-decoration: none;
}
.fooler-down-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-block-start: 4rem;
}

.footer-logo {
  position: absolute;
  top: 0;
  right: -200px;
  transform: translateX(-50%);
  z-index: -1;
}

.social-link {
  width: 50px;
  height: 50px;
  background-color: #3e3e3e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 0.1s;
}

.social-link:hover {
  background-color: #11c4a3;
}

@media (max-width: 1024px) {
  .footer-logo {
    width: auto;
    height: auto;
    right: -25%;
  }

  .footer-section {
    padding-inline: 4rem;
    padding-block: 2rem;
  }

  .footer-left-section {
    grid: 1rem;
  }

  .app-logo {
    width: 50%;
    height: auto;
    padding-block-end: 1rem;
  }

  .footer-para {
    padding-inline-end: 1rem;
    font-size: 1rem;
  }

  .footer-right-section {
    gap: 2rem;
  }

  .text {
    color: #adadad;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    padding-block-end: 1rem;
  }

  .item-link {
    padding-block-end: 0.8rem;
    padding-left: 2rem;
  }

  .footer-para2 {
    padding-block-start: 4rem;
    color: #adadad;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
  }

  .info {
    color: #adadad;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    text-align: end;
    padding-right: unset;
    padding-block-start: 2rem;
  }

  strong {
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
  }
}

@media (max-width: 991px) {
  .footer-logo {
    display: none !important;
  }

  .footer-left-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    text-align: center;
  }

  .app-logo {
    width: 60%;
    height: auto;
    padding-block-end: 2rem;
  }

  .footer-para {
    color: #8e8e8e;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;
    padding-inline-end: unset;
    text-align: center;
  }

  .footer-right-section {
    gap: 2rem;
  }

  .text {
    color: #adadad;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    padding-right: unset;
    padding-block-end: 1rem;
  }

  .item-link {
    gap: 1rem;
    padding-block-end: 1rem;
    padding-left: unset;
  }

  .footer-para2 {
    padding-block: 2rem 1rem;
  }

  .info {
    font-size: 1.2rem;
    text-align: center;
    padding-right: unset;
    padding-block-start: 2rem;
  }

  strong {
    color: #000;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
  }
  .fooler-down-text {
    display: flex;
    flex-direction: column !important;
    padding-block-start: 3rem;
  }
  .footer-para2 {
    padding-block-start: unset;
    padding-block: unset;

    text-align: center !important;
  }
}

@media (max-width: 767px) {
  .main-footer-section {
    max-width: 100%;
  }

  .footer-section {
    padding-inline: unset;
  }

  .footer-left-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    text-align: center;
  }

  .app-logo {
    width: 60%;
    height: auto;
    padding-block-end: 2rem;
  }

  .footer-para {
    color: #8e8e8e;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;
    padding-inline-end: unset;
    text-align: center !important;
    width: unset;
  }

  .footer-right-section {
    gap: 2rem;
  }

  .text {
    font-size: 1.2rem;
    text-align: center;
    padding-right: unset;
  }

  .item-link {
    gap: 1rem;
    padding-block-end: 1rem;
    padding-left: unset;
  }

  .footer-para2 {
    padding-block-start: 2rem;
    text-align: center !important;
  }

  .info {
    text-align: center;
    padding-right: unset;
    padding-block-start: 1rem;
  }

  .footer-logo {
    display: none !important;
  }
  .fooler-down-text {
    display: flex;
    flex-direction: column !important;
  }
}
