.ticketSection {
  background: url("../../assets/stadium.png") no-repeat;
  background-size: cover;
  display: none !important;
  flex-direction: row;
}
.imageSection {
  display: inline-flex;
  height: 100%;
}
.bgImg {
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.textGrid {
  /* padding-left: 1rem; */
  display: flex;
  gap: 0.5rem;
  /* flex-direction: column !important; */
  align-items: center !important;
}
.imgGrid {
  padding: 6rem 4rem 7rem 5rem;

  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}
.ticketText {
  font-family: "Outfit", sans-serif !important;
  font-weight: 700 !important;
  font-size: 81px !important;
  line-height: 116px !important;
}
.acceptImg {
  width: 315px;
  height: 283px;
}
.makeImg {
  width: 411px;
  height: 206px;
}
@media (max-width: 1440px) {
  /* .imageSection {
    height: 322px;
  } */
  .bgImg {
    width: 100%;
    height: 100%;
  }
  .acceptImg {
    width: 236px;
    height: 212px;
  }
  .makeImg {
    width: 308px;
    height: 155px;
  }
  .ticketText {
    font-size: 61px !important;
    line-height: 87px !important;
  }
}
@media (max-width: 767px) {
  .imageSection {
    display: none;
  }
  .ticketSection {
    display: flex !important;
  }
  .acceptImg {
    width: 89px;
    height: 79px;
  }
  .makeImg {
    width: 71px;
    height: 35px;
  }
  .imgGrid {
    padding: 3rem 1.5rem;
    text-align: center;
  }
  .ticketText {
    font-size: 32px !important;
    line-height: 31px !important;
  }
  .textGrid {
    /* padding-left: 3rem;
    padding-bottom: 2.5rem; */
    flex-direction: column !important;
    justify-content: center !important;
  }
}
