.ticketContainer {
  display: none !important;
}
.buttonGrid {
  display: flex;
  justify-content: center;
  height: 150px;
}
.btn {
  font-family: "Outfit", sans-serif !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 25.2px !important;
}

@media (max-width: 767px) {
  .ticketContainer {
    display: block !important;
  }
}
