.baseball {
  padding-block: 4rem;
  background: url("../../assets/screens/baseballscreen.jpg") no-repeat, #000000;
  background-size: cover;
  background-position: 0% 60%;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.baseball-wrapper {
  display: flex !important;
  max-width: 80%;
  /* overflow: hidden; */
}

.text-section-left {
  padding-block: 2rem;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  text-align: left;
}

.approved-app {
  color: #fff !important;
  font-size: 4rem !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}

.available-para-text {
  color: #fff !important;
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  width: 60%;
}

.text-section-right {
  padding-block: 2rem;
  display: flex;
  flex-direction: column !important;
  gap: 1rem;
  border-left: 1px solid #fff;
  padding-left: 6rem;
}

.android-devices-text {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 135%;
}

.playstore-icons {
  width: 20rem;
  height: auto;
}

.appstore-icons {
  width: 20rem;
  height: auto;
}

@media (max-width: 1024px) {
  .baseball-wrapper {
    padding-block-end: 2rem;
    max-width: 90%;
    overflow: hidden;
  }
  .baseball {
    padding-block: 2rem;
    padding-inline: unset;
  }

  .text-section-left {
    padding-block-end: 2rem;
    gap: 1rem;
    text-align: left;
  }

  .approved-app {
    font-size: 2rem !important;
    font-weight: 600 !important;
    line-height: 150% !important;
    width: 80%;
    text-align: left;
  }
  .available-para-text {
    font-size: 1.1rem !important;
    font-weight: 400 !important;
    line-height: 150% !important;
    width: 80%;
    text-align: left;
  }
  .playstore-icons {
    width: 10rem;
    height: auto;
  }

  .appstore-icons {
    width: 10rem;
    height: auto;
  }

  .android-devices-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 135%;
  }
}
@media (max-width: 991px) {
  .text-section-left {
    padding-block-start: 2rem;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .approved-app {
    font-size: 1.8rem !important;
    font-weight: 600 !important;
    line-height: 150% !important;
    text-align: center;
  }
  .text-section-right {
    padding-block: 1rem;
    padding-left: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: unset;
    border: none;
    text-align: center;
  }

  .available-para-text {
    font-size: 1rem !important;
    line-height: 150% !important;
    width: 90%;
    text-align: center;
  }
  .playstore-icons {
    width: 15rem;
    height: auto;
  }

  .appstore-icons {
    width: 15rem;
    height: auto;
  }

  .android-devices-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 135%;
  }
}

@media (max-width: 767px) {
  .baseball-wrapper {
    padding: 0;
  }

  .text-section-left {
    padding-block-start: 2rem;
    gap: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .approved-app {
    font-size: 1.8rem !important;
    font-weight: 600 !important;
    line-height: 150% !important;
    text-align: center;
  }
  .text-section-right {
    padding-block: 1rem;
    padding-left: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: unset;
    border: none;
  }

  .available-para-text {
    font-size: 1rem !important;
    line-height: 150% !important;
    width: 90%;
    text-align: center;
  }
  .playstore-icons {
    width: 15rem;
    height: auto;
  }

  .appstore-icons {
    width: 15rem;
    height: auto;
  }

  .android-devices-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 135%;
  }
}
