.formContainer {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/noise-02\ 1.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.formBox {
  width: 50vw;
}
.formHeading {
  text-align: center;
  padding-block: 5.5rem;
  font-family: "Outfit", sans-serif !important;
  font-weight: 400 !important;
  line-height: 56.7px !important;
  font-size: 45px !important;
  text-transform: uppercase;
  color: #ffffff;
}
.formGroup {
  display: flex;
}
.button-container {
  display: grid;
  place-items: center;
  padding-block: 3rem !important;
}
.submit-button {
  padding-block: 1rem !important;
  padding-inline: 2.5rem !important;
  border-radius: 20px !important;
  background-color: #0bec81 !important;
  color: #000 !important;
  text-transform: none !important;
  text-align: center;
  font-size: 30px !important;
  font-family: "Outfit", sans-serif !important;
  font-weight: 400;
  line-height: 37.8px !important;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}
.inputTitle {
  font-family: "Outfit", sans-serif !important;
  font-size: 24px !important;
  margin: 10px !important;
}

@media (max-width: 1440px) {
  .formHeading {
    padding-block: 4rem;
    line-height: 42.5px !important;
    font-size: 34px !important;
  }
  .submit-button {
    font-size: 22px !important;
    line-height: 28.35px !important;
  }
  .inputTitle {
    font-size: 18px !important;
  }
}
@media (max-width: 767px) {
  .formBox {
    width: 85vw;
  }
  .formHeading {
    padding-block: 1rem;
    line-height: 17.64px !important;
    font-size: 14px !important;
  }
  .submit-button {
    padding-block: 0.65rem !important;
    padding-inline: 1.125rem !important;
    border-radius: 10px !important;
    font-size: 12px !important;
    line-height: 15px !important;
    text-transform: capitalize !important;
  }
  .button-container {
    padding-block: 1.4rem !important;
  }
  .inputTitle {
    font-family: "Outfit", sans-serif !important;
    font-size: 14px !important;
    margin: 10px !important;
  }
}
@media (max-width: 767px) {
  .formBox {
    width: 85vw;
  }
  .formHeading {
    padding-block: 1rem;
    line-height: 17.64px !important;
    font-size: 14px !important;
  }
  .submit-button {
    padding-block: 0.65rem !important;
    padding-inline: 1.125rem !important;
    border-radius: 10px !important;
    font-size: 12px !important;
    line-height: 15px !important;
    text-transform: capitalize !important;
  }
  .button-container {
    padding-block: 1.4rem !important;
  }
  .inputTitle {
    font-family: "Outfit", sans-serif !important;
    font-size: 14px !important;
    margin: 10px !important;
  }
}
