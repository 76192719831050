.ticketBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 500px !important;
  color: white;
  background-color: #333333;
  overflow: hidden;
  position: relative;
}

.ticketBox .box {
  min-height: 100%;
  border: "1px solid red !important";
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 68px;
}

.ticketBox .box.right {
  justify-content: flex-end;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) all;
  display: flex;
}
@media (max-width: 1440px) {
  .ticketBox {
    height: 400px !important;
  }
  .ticketBox .box {
    font-size: 41px;
    line-height: 51px;
  }
}
/* .rightHoverBox { */
/* display: none; */
/* position: absolute;
  width: 50%;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0bec81;
  padding-inline: 5rem;
  opacity: 0;
  transform: translateX(200px);
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) all;
}

.title {
  font-family: "Outfit", sans-serif !important;
  font-weight: 600 !important;
  line-height: 35px;
  font-size: 30px;
  color: #000000;
}
.subTitle {
  font-family: "Outfit", sans-serif !important;
  font-weight: 400 !important;
  line-height: 35px;
  font-size: 30px;
  color: #000000;
}
.leftHoverBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: right;
  background-color: #0bec81;
  padding-inline: 5rem;
  opacity: 0;
  transform: translateX(-200px);
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) all;
} */
.textBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.ticketBox:has(.leftGrid:hover) .rightHoverBox {
  opacity: 1;
  transform: translateX(0px);
}
.ticketBox:has(.leftGrid:hover) .right {
  display: none;
  opacity: 0;
}
.ticketBox:has(.rightGrid:hover) .leftHoverBox {
  opacity: 1;
  transform: translate(0px);
}
.ticketBox:has(.rightGrid:hover) .left {
  display: none;
  opacity: 0;
}
