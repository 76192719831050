.logo-text {
  background: url("../../assets/field-04.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 5rem;
  padding-block: 5rem;
}
.text-section {
  padding-inline: 8rem;
  padding-top: 1.5rem;
  align-items: center;
}
.logoHeading {
  font-family: "Outfit", sans-serif !important;
  line-height: 37.8px;
  font-weight: 700 !important;
  color: #0bec81;
  font-size: 30px !important;
}
.subHeading {
  font-family: "Outfit", sans-serif !important;
  line-height: 37.8px;
  font-weight: 400 !important;
  color: #fff;
  font-size: 30px !important;
}
.imgLogo2 {
  width: 626px;
  height: 185px;
}
@media (max-width: 1440px) {
  .imgLogo2 {
    width: 469px;
    height: 139px;
  }
  .logoHeading {
    line-height: 28.35px !important;
    font-size: 22px !important;
  }
  .subHeading {
    line-height: 28.35px !important;
    font-size: 22px !important;
  }
  .text-section {
    padding-inline: 3rem;
  }
}
@media (max-width: 767px) {
  .imgLogo2 {
    width: 107px;
    height: 32px;
  }
  .logoHeading {
    font-size: 12px !important;
    line-height: 14.4px !important;
  }
  .subHeading {
    font-size: 12px !important;
    line-height: 14.4px !important;
  }
  .logo-text {
    padding-inline: 1.5rem 1rem;
    padding-block: 2rem 1.5rem;
  }
  .text-section {
    padding-inline: 1rem;
    padding-top: 0rem;
  }
}
