.leftHoverBox {
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(-200px);
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) all;
}
.buyerInnerBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;
  background-color: #333333;
  padding-inline: 5rem;
  gap: 1rem;
}
.buyerTitle {
  font-family: "Outfit", sans-serif !important;
  font-weight: 600;
  text-align: left;
  font-size: 40px !important;
  line-height: 43px;
  margin-block: 20px !important;
  color: #ffffff;
  /* width: 54%; */
}
.buyerDescription {
  font-family: "Outfit", sans-serif !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  font-size: 24px !important;
  color: #ffffff;
}
.getPriceImg {
  width: 130px;
  height: 130px;
}
@media (max-width: 1440px) {
  .getPriceImg {
    width: 80px;
    height: 80px;
  }
  .buyerTitle {
    font-size: 34px !important;
    line-height: 40px !important;
  }
  .buyerDescription {
    line-height: 28px !important;
    font-size: 22px !important;
  }
}
