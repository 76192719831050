.innerBoxBuyer {
  width: 100%;
  height: 210px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;
  background-color: #333333;
  padding-right: 2rem;
  gap: 1rem;
}
.titleBuyer {
  font-family: "Outfit", sans-serif !important;
  font-weight: 600;
  text-align: right;
  font-size: 20px !important;
  line-height: 40px;
  /* margin-block: 10px !important; */
  color: #ffffff;
  /* width: 54%; */
}
.descriptionBuyer {
  font-family: "Outfit", sans-serif !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  font-size: 12px !important;
  color: #ffffff;
}
.priceImg {
  width: 61px;
  height: 61px;
}
