.logo-link{
      padding: 0.5rem 0.5rem;
}
.logo-link > img{
      width: 12%;
      height: auto;
      cursor: pointer;
}
.appbar{
      box-shadow: unset !important;
      background-color: #fff !important;
}