.thank-you-offer-logo {
  display: flex;
  justify-content: space-between;
  padding: 30px 15px;
  margin-bottom: 30px;
}

.thank-you-offer-logo img {
  width: 375px;
  height: 50px;
  object-fit: contain;
}

.thankyou-typography {
  color: #8e8e8e;
  text-align: center;
  font-family: Outfit;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 30%;
  margin-block: 2rem;
}

.thankyou-button-style {
  border-radius: 8px;
  background: #11c4a3;
  width: 236px;
  height: 76px;
  color: #ffffff;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 1.62px;
  text-transform: uppercase;
}
