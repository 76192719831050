.logo-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 5rem;
  padding-block: 4rem 3rem;
  background-color: #333333;
}
.mainLogo {
  width: 355px;
  height: 45px;
}
.logo {
  width: 455px;
  height: 22px;
}
.ticketsTitle {
  font-family: "Outfit", sans-serif !important;
  line-height: 37.8px;
  font-weight: 700 !important;
  text-transform: uppercase;
  color: #0bec81;
  font-size: 30px !important;
}
.termsTitle {
  font-family: "Outfit", sans-serif !important;
  line-height: 37.8px;
  font-weight: 700 !important;
  text-transform: uppercase;
  color: #fff;
  font-size: 30px !important;
}
section {
  display: flex;
  gap: 1rem;
}
.textSpan {
  /*   border: 1px solid red; */
  margin: 0;
  vertical-align: middle;
  display: flex;
  font-family: "Outfit", sans-serif !important;
  line-height: 37.8px;
  font-weight: 700 !important;
  text-transform: uppercase;
  color: #0bec81;
  font-size: 30px !important;
}
ul {
  max-height: 2em;
  margin: 0;
  /*   border: 1px solid red; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  padding-left: 0 !important;
  list-style: none !important;
}
ul > li {
  margin: 0;
  padding: 0;
  display: block;
  transition: transform 0.25s ease;
}
li {
  transform: translateY(calc(var(--fac) * 1.33 * -1.75rem));
  font-family: "Outfit", sans-serif !important;
  line-height: 37.8px;
  font-weight: 700 !important;
  text-transform: uppercase;
  color: #fff;
  font-size: 30px !important;
}

@media (max-width: 1440px) {
  .mainLogo {
    width: 266px;
    height: 34px;
  }
  .logo {
    width: 341px;
    height: 16px;
  }
  .ticketsTitle {
    line-height: 28.35px !important;
    font-size: 22px !important;
  }
  .termsTitle {
    line-height: 28.35px !important;
    font-size: 22px !important;
  }
}
@media (max-width: 767px) {
  .logo-section {
    padding-inline: 1.5rem;
    padding-block: 1rem;
    flex-direction: column;
    gap: 0.75rem;
  }
  .textSpan {
    font-size: 22px !important;
    line-height: 28.35px !important;
  }
  li {
    font-size: 22px !important;
    line-height: 28.35px !important;
  }
  .mainLogo {
    width: 192px;
    height: 24px;
  }
  .scrollingText {
    display: none;
  }
  /* ul {
    max-height: 1.5em;
  } */
}
