.outerBox {
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../assets/court-06.png");
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-inline: 5rem;
  padding-top: 4rem;

  /* gap: 2.5rem; */
}
.memberBox {
  background-color: #e2e2e2;
  text-align: center;
  padding: 2rem 2rem 5rem 2rem;
  border-radius: 1rem 1rem 0 0;
}
.memberHeading {
  font-family: "Outfit", sans-serif !important;
  text-transform: uppercase;
  font-weight: 400 !important;
  line-height: 56.7px !important;
  color: #000;
  font-size: 45px !important;
  margin-bottom: 45px !important;
}
.memberText {
  text-transform: none !important;
  font-family: "Outfit", sans-serif !important;
  color: #000;
  font-weight: 400 !important;
  line-height: 37.8px !important;
  font-size: 30px !important;
  margin-bottom: 45px !important;
}
.preferredBox {
  background-color: #797979;
  text-align: center;
  padding: 2rem 2rem 5rem 2rem;
  border-radius: 1rem 1rem 0 0;
}
.preferedHeading {
  font-family: "Outfit", sans-serif !important;
  text-transform: uppercase;
  font-weight: 400 !important;
  line-height: 56.7px !important;
  color: #fff;
  font-size: 45px !important;
  margin-bottom: 45px !important;
}
.preferedText {
  text-transform: none !important;
  font-family: "Outfit", sans-serif !important;
  color: #fff;
  font-weight: 400 !important;
  line-height: 37.8px !important;
  font-size: 30px !important;
  margin-bottom: 45px !important;
}
.logoBox {
  background-color: #000;
  text-align: center;
  padding: 3rem 2rem 5rem 2rem;
  border-radius: 1rem 1rem 0 0;
}

.logoImg {
  width: 340px;
  height: 100px;
  margin-bottom: 45px !important;
}
@media (max-width: 1440px) {
  .logoImg {
    width: 255px;
    height: 75px;
  }
  .memberHeading {
    font-size: 34px !important;
    line-height: 42.5px !important;
  }
  .memberText {
    line-height: 28.35px !important;
    font-size: 22px !important;
  }
  .preferedHeading {
    font-size: 34px !important;
    line-height: 42.5px !important;
  }
  .preferedText {
    line-height: 28.35px !important;
    font-size: 22px !important;
  }
}
@media (max-width: 767px) {
  .memberHeading {
    font-size: 12px !important;
    line-height: 10px !important;
    margin-bottom: 11px !important;
  }
  .memberText {
    font-size: 7px !important;
    line-height: 10px !important;
    margin-bottom: 11px !important;
  }
  .preferedHeading {
    font-size: 12px !important;
    line-height: 10px !important;
    margin-bottom: 11px !important;
  }
  .preferedText {
    font-size: 7px !important;
    line-height: 10px !important;
    margin-bottom: 11px !important;
  }
  .outerBox {
    padding-inline: 1.5rem;
    padding-top: 0.9rem;
  }
  .memberBox {
    padding: 0.8rem 0.5rem 1.2rem 0.5rem;
    border-radius: 10px 10px 0 0;
  }
  .logoBox {
    padding: 0.8rem 0.5rem 1.2rem 0.5rem;
    border-radius: 10px 10px 0 0;
  }
  .preferredBox {
    padding: 0.8rem 0.5rem 1.2rem 0.5rem;
    border-radius: 10px 10px 0 0;
  }
  .logoImg {
    width: 67px;
    height: 20px;
    margin-bottom: 11px !important;
  }
}
