.rightHoverBox {
  /* display: none; */
  position: absolute;
  height: 100%;
  max-width: 100%;
  opacity: 0;
  transform: translateX(200px);
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) all;
}
.ticketInnerBox {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  background-color: #0bec81;
  padding-inline: 5rem;
}

.rightTitle {
  font-family: "Outfit", sans-serif !important;
  font-weight: 600 !important;
  line-height: 35px;
  font-size: 30px;
  color: #000000;
}
.rightSubTitle {
  font-family: "Outfit", sans-serif !important;
  font-weight: 400 !important;
  line-height: 35px;
  font-size: 30px;
  color: #000000;
}
