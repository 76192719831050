.rightHoverBox {
  /* display: none; */
  position: absolute;
  height: 100%;
  max-width: 100%;
  opacity: 0;
  transform: translateX(200px);
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) all;
}
.sellerInnerBox {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  gap: 1rem;
  background-color: #0bec81;
  padding-inline: 5rem;
}
.sellerTitle {
  font-family: "Outfit", sans-serif !important;
  font-weight: 600;
  text-align: left;
  font-size: 40px !important;
  line-height: 43px;
  margin-block: 20px !important;
  color: #000;
}
.sellerDescription {
  font-family: "Outfit", sans-serif !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  font-size: 24px !important;
  color: #000;
}
@media (max-width: 1440px) {
  .getPriceImg {
    width: 80px;
    height: 80px;
  }
  .sellerTitle {
    font-size: 34px !important;
    line-height: 40px !important;
  }
  .sellerDescription {
    line-height: 28px !important;
    font-size: 22px !important;
  }
}
