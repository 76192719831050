.footerSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.textWithLogo {
  padding: 5rem 0 2rem 5rem;
  width: 590px;
  height: 365px;
}
.logo2 {
  width: 362px !important;
  height: 47px !important;
}
.footerImg {
  width: 565px !important;
  height: 484px !important;
  display: none;
}
.showImg {
  display: block;
}
.iconImg {
  width: 26px !important;
  height: 26px !important;
}
.fbImg {
  width: 16px !important;
  height: 28px !important;
}
.copyText {
  color: #000000;
  font-family: "Outfit", sans-serif !important;
  font-size: 18px !important;
  line-height: 22.68px !important;
  font-weight: 400 !important;
}
.linkText {
  color: #0bec81 !important;
  font-family: "Outfit", sans-serif !important;
  font-size: 18px !important;
  line-height: 22.68px !important;
  font-weight: 400 !important;
}
.iconBtn {
  min-width: auto !important;
}
@media (max-width: 1440px) {
  .footerImg {
    width: 424px !important;
    height: 363px !important;
  }
  .logo2 {
    width: 271px !important;
    height: 35px !important;
  }
  .copyText {
    font-size: 14px !important;
    line-height: 17px !important;
  }
  .linkText {
    font-size: 14px !important;
    line-height: 17px !important;
  }
}
@media (max-width: 767px) {
  .logo2 {
    width: 102px !important;
    height: 15px !important;
  }

  .iconImg {
    width: 12px !important;
    height: 12px !important;
  }
  .fbImg {
    width: 8px !important;
    height: 12px !important;
  }
  .textWithLogo {
    padding: 1.5rem 0 1.5rem 1.5rem;
    height: auto;
  }
  .copyText {
    font-size: 6px !important;
    line-height: 7.56px !important;
  }
  .linkText {
    font-size: 6px !important;
    line-height: 7.56px !important;
  }
  .footerImg {
    display: none;
  }
  .iconBtn {
    padding: 4px !important;
  }
}
