.newContainer {
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.formBox {
  width: 50vw;
}
.heading {
  text-align: center;
  padding-block: 3rem;
  font-family: "Outfit", sans-serif !important;
  font-weight: 700 !important;
  line-height: 56.7px !important;
  font-size: 54px !important;
  text-transform: uppercase;
  color: #000;
}
.formGroup {
  display: flex;
}
.button-container {
  display: grid;
  place-items: center;
  padding-block: 2.7rem !important;
}
.submit {
  padding-block: 1rem !important;
  padding-inline: 2.5rem !important;
  border-radius: 20px !important;
  background-color: #0bec81 !important;
  color: #000 !important;
  text-transform: uppercase !important;
  text-align: center;
  font-size: 30px !important;
  font-family: "Outfit", sans-serif !important;
  font-weight: 400;
  line-height: 37.8px !important;
  outline: 1px solid #353535 !important;
  cursor: pointer;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}
.formImg {
  position: absolute;
  width: 755px;
  height: 647px;
  right: 0;
  top: 8px;
  /* width: 25%; */
}
.inputLabel {
  font-family: "Outfit", sans-serif !important;
  font-size: 24px !important;
  margin: 10px !important;
}

@media (max-width: 1440px) {
  .heading {
    padding-block: 2.5rem;
    line-height: 42.5px !important;
    font-size: 34px !important;
  }
  .submit {
    font-size: 22px !important;
    line-height: 28.35px !important;
  }
  .formImg {
    width: 566px;
    height: 485px;
  }
  .inputLabel {
    font-size: 18px !important;
  }
}

@media (max-width: 767px) {
  .formBox {
    width: 85vw;
  }
  .heading {
    padding-block: 1rem;
    line-height: 17.64px !important;
    font-size: 14px !important;
  }
  .submit {
    padding-block: 0.65rem !important;
    padding-inline: 1.125rem !important;
    border-radius: 10px !important;
    font-size: 12px !important;
    line-height: 15px !important;
  }
  .button-container {
    padding-block: 1.4rem !important;
  }
  .formImg {
    width: 300px;
    height: 280px;
    right: 0;
    /* width: 25%; */
  }
  .inputLabel {
    font-family: "Outfit", sans-serif !important;
    font-size: 14px !important;
    margin: 10px !important;
  }
}
