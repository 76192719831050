.privacy_body p{
    font-size: 20px;
}
i{
    font-style: italic;
}
strong{
    font-weight: 700 !important;
    font-family: 'Outfit', 'Helvetica Neue', sans-serif !important;
}
h5{
    font-weight: 700 !important;
    font-family: 'Outfit', 'Helvetica Neue', sans-serif !important;
}
h3{
    font-weight: 700 !important;
    font-family: 'Outfit', 'Helvetica Neue', sans-serif !important;

}
.font-bold{
    font-weight: 900;
}
.privacy_body{
    font-family: "Causten-500 !important",
}
.boredr-top{
    border-top: 1px solid #ccc;
    padding-top: 30px;
}
span{
    font-size: 14px !important;
    color: #4c4c4c;
}
a{
    color: #11c4a3 !important;
    text-decoration: none !important;
}
a:hover{
    text-decoration: underline !important;
}
ul{
    list-style: circle !important;
    padding-left: 25px !important;
}
li{
    display: inline-block !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    line-height: 1.75;
    font-size: 18px;
    padding: 0 !important;
    margin-bottom: 20px;
    position: relative;
}
li::after{
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    background-color: #000;
    border-radius: 50%;
    top: 10px;
    left: -25px;
}
.terms-ul li::after{
display: none;
}
.terms-ul {
    padding-left: 40px !important;
}
.terms-ul label{
    position: absolute;
    left: -40px;
    top: 0px;
    font-weight: 700 !important;
    font-family: 'Outfit', 'Helvetica Neue', sans-serif !important;
}
@media (max-width:991px){
    h3{
    font-size: 30px !important;
    padding-bottom: 0 !important;
    } 
    h5{
        font-size: 20px !important;
        } 
        p{
            font-size: 16px !important;
            } 
            strong{
                font-size: 16px !important;
                } 
                .terms-ul {
                    padding-left: 30px !important;
                }
                .terms-ul label {
                    left: -30px;
                }
                span{
                    font-size: 12px !important;
                }
}